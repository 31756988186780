//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'

export default {
  name: 'TasksImportStep1',
  components: {
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      uploadError: null,
      templateUploadingXLSX: false,
      templateUploadingTrello: false,
    }
  },
  computed: {
    downloadTemplateXLSXURL () {
      return api.tasks.getImportTemplateXLSXURL()
    },
    disableButtons () {
      return this.templateUploadingXLSX || this.templateUploadingTrello
    },
  },
  methods: {
    async uploadTemplate (file, type) {
      try {
        let response
        switch (type) {
          case 'xlsx':
            this.templateUploadingXLSX = true
            response = await api.tasks.uploadImportTemplateXLSX(file)
            break
          case 'trello':
            this.templateUploadingTrello = true
            response = await api.tasks.uploadImportTemplateTrello(file)
            break
          default:
            response = []
        }
        const parsedTasks = response.tasks.map(task => {
          task.import = !task._error?.length > 0
          return task
        })
        this.$emit('input', parsedTasks)
      } catch (e) {
        this.uploadError = e.details?.file || e.error
      }
      this.templateUploadingXLSX = false
      this.templateUploadingTrello = false
    },
    handleFileSelection (ev, type) {
      this.uploadError = null
      const files = ev.target?.files
      files?.length > 0 && this.uploadTemplate(files[0], type)
    },
  },
}
